import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate(): Observable <boolean> {
    return this.authService.isAuthenticatedOrRefresh()
      .pipe(
        map(authenticated => {
          if (!authenticated) {
            this.router.navigate(['/auth/login']);
            return false;
          }
          return true;
        }),
      );
  }
}
