import {Inject, Injectable, Injector} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService, NbAuthToken, NbTokenService} from '@nebular/auth';
import {Router} from '@angular/router';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              private router: Router,
              protected tokenService: NbTokenService,
              @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter: (req: HttpRequest<any>) => boolean) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      return this.authService.isAuthenticatedOrRefresh()
        .pipe(
          switchMap(authenticated => {
            if (authenticated) {
              return this.authService.getToken().pipe(
                switchMap((token: NbAuthToken) => {
                  const JWT = `Bearer ${token.getValue()}`;
                  req = req.clone({
                    setHeaders: {
                      Authorization: JWT,
                    },
                  });
                  return next.handle(req);
                })
              );
            } else {
              this.tokenService.clear();
              this.authService.logout('email');
              this.router.navigate(['/auth/login']);
              return next.handle(req);
            }
          }),
        );
    } else {
      return next.handle(req);
    }
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

}
