import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbButtonModule, NbCardModule, NbThemeModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest } from '@angular/common/http';
import {
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
  NbAuthJWTToken,
  NbAuthModule,
  NbPasswordAuthStrategy,
  NbTokenLocalStorage,
  NbTokenStorage
} from '@nebular/auth';
import { environment } from '../environments/environment';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './guards/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NbEvaIconsModule,
        NbButtonModule,
        NbThemeModule.forRoot({name: 'dark'}),
        HttpClientModule,
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token',
                    },
                    baseEndpoint: environment.API_URL,
                    login: {
                        // ...
                        endpoint: '/auth/login',
                    },
                    register: {
                        // ...
                        endpoint: '/auth/register',
                    },
                    refreshToken: {
                        // ...
                        endpoint: '/auth/refresh',
                    },
                }),
            ],
            forms: {
                login: {
                    redirectDelay: 500, // delay before redirect after a successful login, while success message is shown to the user
                    strategy: 'email',  // strategy id key.
                    rememberMe: false,   // whether to show or not the `rememberMe` checkbox
                    showMessages: {     // show/not show success/error messages
                        success: true,
                        error: true,
                    },
                    socialLinks: null, // social links at the bottom of a page,
                    redirect: {
                        success: '/dashboard/',
                        failure: null, // stay on the same page
                    },
                },
            },
        }),
        NbCardModule,
    ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      // useClass: NbAuthJWTInterceptor,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
    // tslint:disable-next-line:max-line-length
    { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: (req: HttpRequest<any>) => req.url.includes('login') || req.url.includes('refresh') }

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
